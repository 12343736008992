.header-container__main {
  padding: 0.7rem 1.7rem;
  height: 5.5rem;
  background: $brand-primary;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    height: 5rem;
  }

  @media (max-width: 425px) {
    padding: 0.3rem 0.8rem;
    height: 4.5rem;
  }

  .header-page--title {
    font-size: 1.2rem;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 425px) {
      font-size: 0.9rem;
    }
  }

  .header-container__inner {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // flex: 1;
    width: 100%;

    .header-left-content__block {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .header-sidebar-toggle {
        display: inline-block;
        // margin-right: 1rem;

        .header-logo--img {
          cursor: pointer;
          height: auto;
          width: 3rem;
          @media screen and (max-width: 768px) {
            height: auto;
            width: 80%;
          }

          @media screen and (max-width: 768px) {
            height: auto;
            width: 80%;
          }

          @media screen and (max-width: 425px) {
            height: auto;
            width: 70%;
          }
          img {
            height: 100%;
            width: 100%;
          }
        }
      }

      @media only screen and (max-width: 768px) {
        .header-logo--img {
          display: none;
        }
      }

      .header-left-icon {
        display: inline-flex;
        margin-right: 1.5rem;
        opacity: 0.7;

        .back-to-link {
          text-decoration: none;
          color: inherit;

          &:hover {
            background: $gray-700;
            font-weight: 600;
          }

          svg {
            font-size: 2.5rem;
          }
        }

        svg {
          font-size: 1.5rem;
        }
      }

      .header-page--title {
        font-size: 1.2rem;
        font-weight: 500;
        // width: 18rem;
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: fit-content;

        @media screen and (max-width: 1024px) {
          // width: fit-content;
          font-size: 1rem;
          // margin-right: 10px;
          // margin-left: -30px;
          align-items: center;
          justify-content: flex-start;
          display: flex;
        }
      }
      .header-page--title.coupon_performance_report {
        width: fit-content;
        @media screen and (max-width: 1024px) {
          // width: 13rem;
          font-size: 1.2rem;
        }
      }

      .header-left--btn {
        margin-left: 1.2rem;

        .header--btn {
          background: #e12f17;
          padding: 0.5rem 1.7rem;
        }

        @media screen and (max-width: 1024px) {
          // margin-left: 0.7rem;
          margin-right: -5px;
          margin-left: -5px;
          display: flex;
          justify-content: flex-start;
          .header--btn {
            padding: 0.5rem 0.7rem;
          }
        }
      }
    }
    .header-right-content__block {
      display: flex;
      align-items: center;
      margin-left: auto;
      height: 100px;
      margin-left: auto;
      .notification-icon {
        opacity: 1;
        margin-right: 10px;
        margin-right: 10px;
        position: relative;
        color: $input-value;
        transition: 0.2s;
        @media screen and (max-width: 768px) {
          margin-right: 0.5rem;
        }
        svg {
          font-size: 1.5rem;
          margin: 8px;
        }
        svg:hover {
          font-size: 1.5rem;
          margin: 8px;
        }

        .notification-list-menu {
          min-width: 425px !important;
          top: 40px !important;

          .notification-dropdown-list__main {
            width: 100%;
            border-bottom: 1px solid #bfd6ec;

            &.list--box {
              border-bottom: unset;
            }

            .list-item--inner {
              display: flex;

              .list-icon--block {
                border: 1px solid $activity-icon-block-border;
                height: 50px;
                width: 50px;
                border-radius: 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.2rem;
                padding: 0.3rem;
                margin-right: 1.5rem;

                .list_icon__inner {
                  position: relative;
                  padding: 0.3rem;

                  .list--icon {
                    color: $sidebar-menu-item;

                    svg {
                      height: 1.5rem;
                      width: 1.5rem;
                    }
                  }
                }
              }

              .list-content__main-block {
                flex: 1;
                width: 100%;
                border-bottom: 1px solid $gray-500;

                .list-content--inner {
                  display: flex;
                  align-items: center;
                  margin-bottom: 1.5rem;

                  .list-details--content-block {
                    display: flex;
                    flex-direction: column;

                    .list-details-block {
                      font-size: 0.875rem;
                      font-weight: 400;
                      margin-bottom: 0.5rem;

                      .meta-info {
                        color: $sidebar-menu-item;
                      }
                    }

                    .list-timing {
                      color: $sidebar-menu-item;
                      font-size: 0.875rem;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .user-profile-icon {
        // margin-right: 10px;
        position: relative;

        @media screen and (max-width: 768px) {
          padding: 0%;
          // margin-right:10%;
          margin-left: 10px;
        }

        svg {
          font-size: 1.5rem;
          margin: 8px;
        }
      }
      .has-dropdown.user-logout {
        background: rgba(190, 33, 11, 0.13);
        border-color: transparent;
        svg {
          font-size: 1.5rem;
          margin: 8px;
        }
      }

      .notification-icon:hover {
        opacity: 1;
        margin-right: 10px;
        position: relative;
        color: $brand-accent;
      }
      .user-profile-icon:hover .user-info--block {
        visibility: visible;
        transform: translate(-45%, -10px);
        opacity: 1;
        transition: 0.3s linear;
      }
      .notification-count {
        background: $brand-accent;
        margin-left: 7px;
        padding: 1px 7.4px 1px 5.3px;
        border-radius: 1rem;
        font-size: 0.8rem;
        font-weight: 400;
        color: white;
        position: absolute;
        top: 0;
        text-align: center;
        left: 1rem;
      }

      .user-info-menu {
        top: 50px !important;
      }

      .user-info--block {
        visibility: hidden;
        z-index: 1;
        opacity: 0.4;
        width: max-content;
        height: max-content;
        background: #182532;
        position: absolute;
        top: 115%;
        left: 50%;
        border-radius: 9px;
        padding: 0px 5px;
        transform: translate(-45%, 9px);
        transition: all 0.3s ease-in-out;
        box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);
        ::after {
          content: ' ';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 6.5px 0 6.5px;
          border-color: rgb(190, 33, 11) transparent transparent transparent;
          position: absolute;
          top: -17%;
          left: 40%;
          transform: rotate(180deg);
        }
        .user__profile-pic {
          .avatar {
            height: 2.5rem;
            width: 2.5rem;
            margin-right: 1rem;

            @media screen and (max-width: 768px) {
              margin-right: 0.5rem;
            }
          }
        }

        .user__info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: max-content;
          overflow: hidden;

          .user--name {
            font-size: 1rem;
          }

          .user--role {
            font-size: 0.8rem;
            font-weight: 300;
            color: #bfbfbf;
          }
        }

        .arrow__icon {
          margin-left: 2rem;
          color: $brand-accent;
        }

        .arrow__icon:focus {
          margin-left: 2rem;
          color: $brand-accent;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .header-container__main {
    padding: 0.5rem 1rem;
    height: auto;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
  }

  .header-container__inner {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .header-left-content__block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    width: auto;
  }

  .header-page--title {
    font-size: 1rem;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .header-left--btn .header--btn {
    padding: 0.4rem 1rem;
    width: auto;
  }

  .header-right-content__block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    // gap: 0.5rem;
    width: auto;
  }

  .notification-icon,
  .user-profile-icon {
    margin-left: 0.8rem;
    svg {
      font-size: 1.2rem;
    }
  }
}

@media screen and (min-width: 375px) {
  .header-container__main .header-container__inner .header-right-content__block {
    display: flex;
    align-items: center;
    margin-left: auto;
    height: 100px;
    gap: 3rem;
  }

  .header-container__main .header-container__inner .header-left-content__block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }

  .header-container__main .header-container__inner .header-left-content__block .header-page--title {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

@media only screen and (max-width: 427px) {
  .header-container__main .header-container__inner .header-left-content__block .header-left-icon {
    display: inline-flex;
    margin-right: 0px;
    opacity: 0.7;
  }

  .header-container__main .header-container__inner .header-left-content__block .header-page--title {
    font-size: 1rem;
    font-weight: 500;
  }

  .header-container__main .header-container__inner .header-right-content__block {
    display: flex;
    align-items: center;
    margin-left: auto;
    height: 100px;
    gap: 0.5rem;
  }
}

@media only screen and (max-width: 360px) {
  .header-container__main .header-container__inner .header-right-content__block .user-profile-icon svg {
    font-size: 1rem;
  }
  .header-container__main .header-container__inner .header-right-content__block .has-dropdown.user-logout svg {
    font-size: 1rem;
  }
  .header-container__main .header-container__inner .header-left-content__block .header-page--title {
    font-size: 1rem;
    font-weight: 500;
  }
  .header-container__main .header-container__inner .header-right-content__block {
    display: flex;
    align-items: center;
    margin-left: auto;
    height: 100px;
    gap: 0rem;
  }

  .header-container__main {
    padding: 0.5rem 0.4rem;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: hidden;
  }
  .header-container__main .header-container__inner .header-left-content__block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.2rem;
  }
}
