.data-report--main {
  .data-report--block {
    // .main-card-container {
    //   max-width: 99rem;

    .data-report__inner {
      .data-report__box {
        margin-bottom: 1rem;

        .data-report__row {
          .data-report-search-box {
            padding-top: 0.9rem;
            padding-left: 2rem;

            .report-search-icon {
              cursor: pointer;
            }
          }

          .log-filter-block {
            display: flex;
            padding: 0.75rem;

            .log-filter-icon {
              display: flex;
              align-items: center;

              .log-filter-label {
                padding-left: 0.5rem;
                font-size: 0.875rem;
                font-weight: 600;
              }
            }

            .log-arrow__icon {
              color: $brand-accent;
              margin-left: auto;
            }
          }

          .log-filter-dropdown {
            min-width: 12rem;
            margin-right: 1rem;

            @media screen and (max-width: 1024px) {
              min-width: 10rem;
            }

            @media screen and (max-width: 768px) {
              min-width: 8rem;
            }
          }

          .report-search-button {
            border: none;
            cursor: pointer;
            border-radius: 15px;
            padding: 0.8rem 1rem;
            font-size: 0.9rem;
          }

          .report-search-excel-block {
            padding-left: 0.5rem;
            // padding-top: 0.5rem;
          }

          .report-search-box {
            // padding-top: 0.5rem !important;
            .advance-filter-btn {
              .primary-button {
                padding: 0.2rem 1rem 0.2rem 1rem;
              }
            }
          }

          .excel-icon--block {
            padding-top: 0rem;

            .report-excel-icon {
              cursor: pointer;
              background: $gray-600;
              border: 3px solid $gray-600;
              box-sizing: border-box;
              border-radius: 15px;

              svg {
                font-size: 1.5rem;
                margin: 8px;
              }
            }
          }

          .advance-filter-icon {
            cursor: pointer;
            background: $gray-600;
            border: 3px solid $gray-600;
            box-sizing: border-box;
            border-radius: 15px;

            svg {
              font-size: 1.5rem;
              margin: 8px;
            }
          }
        }

        .react-select .react-select__control {
          border: none;
          box-shadow: none !important;
          min-height: 32px;
          cursor: pointer;
          background: $gray-600;
          padding: 0.1rem;
          border-radius: 0 !important;
        }
      }

      .data-report__table {
        .record-list-table {
          .station-link,
          .charger-link {
            cursor: pointer;
            text-align: left;
            text-wrap: wrap;
          }
          .station-link:hover,
          .charger-link:hover {
            text-decoration: underline;
          }

          .dropdown-link {
            cursor: pointer;
          }

          .dropdown-link svg:hover {
            color: $brand-accent;
          }
        }

        .record-list-table.chargerstatus-list-table {
          .station-link,
          .charger-link {
            cursor: pointer;
            svg {
              margin-right: 10px;
              height: 25px;
            }
          }

          .station-link {
            flex: 25%;
            display: flex;

            svg {
              flex: 30px;
            }

            span {
              white-space: pre-line;
              flex: 100%;
              width: 270px;
            }
          }

          .station-link:hover,
          .charger-link:hover {
            text-decoration: underline;
          }

          .dropdown-link {
            cursor: pointer;
            text-align: right;

            svg {
              border-radius: 10px;
              background: $tab-active-color;
              padding: 10px;
              width: 36px;
              height: 36px;
            }
          }

          .dropdown-link svg:hover {
            color: $brand-accent;
          }

          thead {
            tr {
              border-bottom: 0px;
              display: flex;
              flex-direction: row;

              th {
                border-color: $tab-active-color;
                flex: 1;
                border-radius: 0px;
                &:hover {
                  border-radius: 10px;
                }
              }

              th:first-child {
                flex: 24.5%;
              }

              th:nth-child(3) {
                flex: 7%;
              }

              @media only screen and (max-width: 1700px) {
                th:nth-child(3) {
                  flex: 0%;
                }

                th {
                  flex: 1.1;
                }
              }
            }
          }

          tbody {
            tr {
              display: flex;
              flex-direction: row;
              border-bottom: 0px;

              td {
                background-color: transparent;
                border-bottom: 0px;
                flex: 1;
              }

              // .con {
              //   display: contents;
              // }
            }

            .sub-table {
              margin: 10px;

              tr {
                margin: 0px;
                border-radius: 15px 15px 0px 0px;
                border: 0px;
                display: flex;
              }
            }
          }

          .charger-status-tr {
            display: flex;
            flex-direction: column;
            border-radius: 20.453px;
            border: 1.023px solid $tab-active-color;
            margin: 10px 0px;
          }

          // trial

          @media only screen and (max-width: 768px) {
            .charger-status-tr tr {
              width: 240px;
              height: 335px;
              display: flex;
              flex-direction: column;
              text-align: center;
              // padding: 5px;
              // margin: 5px 0;
              border: 1px solid #fff;
              border-radius: 5px;
              background-color: #1b2a38;
            }

            .sub-table tr {
              width: 250px;
              height: auto;
              display: flex;
              flex-direction: column;
              text-align: center;
              // padding: 5px;
              // margin: 5px 0;
              border: 1px solid #fff;
              border-radius: 10px;
              background-color: #1b2a38;
            }

            .sub-table .sub-table-head-row {
              display: none;
            }

            .sub-table .sub-table-body-row {
              display: flex;
              flex-direction: inherit;
              width: 200px;
            }

            tbody .sub-table {
              overflow: scroll;
              margin: 0px;
              margin-top: 10px;
            }

            .sub-table .sub-table-body-row {
              td {
                display: none;
              }

              td:nth-child(1),
              td:nth-child(2) {
                display: table-cell;
                border-radius: 25px;
                border-bottom-left-radius: 25px;
                margin: 0px;
                margin-bottom: 2px;
                padding-top: 5px;
                padding-bottom: 0px;
                width: fit-content;
              }
            }
          }
          thead .charger-status-header {
            display: none;
            background-color: yellow;
            color: #be210b;
            border: blue;
          }

          .data-report__table {
            .record-list-table {
              .station-link svg,
              .charger-link svg {
                display: block;
                margin: 0 auto 5px;
                height: 40px;
                width: 40px;
              }

              .station-link span,
              .charger-link span {
                display: block;
                text-align: center;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .station-link:hover,
              .charger-link:hover {
                text-decoration: none;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
              }

              .dropdown-link {
                display: none; /* Hiding dropdown for mobile for cleaner layout */
              }
            }

            .charger-status-tr {
              flex-direction: column;
              padding: 15px;
              margin: 10px 0;
              background-color: #1b2a38;
              border: 1px solid #fff;
              border-radius: 10px;
            }

            thead {
              display: none; /* Hide table headers for mobile */
            }

            tbody {
              tr {
                display: block;
                padding: 10px;
                margin: 5px 0;
                background-color: #1b2a38;
                border: 1px solid $tab-active-color;
                border-radius: 10px;
              }

              td {
                display: block;
                text-align: center;
                padding: 5px 0;
                font-size: 12px;
              }
            }
          }
        }

        .charger-status-tr tr td:nth-child(3),
        .charger-status-tr tr th:nth-child(3) {
          flex: 8% !important;
        }

        .status_charger {
          border-radius: 30.68px;
          padding: 5px 20px;
          text-align: center;
        }

        .status-Available {
          background: #0f9b72;

          svg {
            display: none;
          }
        }

        .status-PowerLoss {
          background: #be210b;

          svg {
            display: none;
          }
        }

        .status-Unavailable {
          background: #ff8b00;

          svg {
            display: none;
          }
        }

        .status-Charging,
        .status-In,
        .status_charger.Use {
          background: $semantic-info;
          box-shadow: 0px 0px 4px 4px $status-breathing-blue;
          position: relative;
          flex-wrap: wrap;
          display: flex;
          justify-content: space-around;
          animation-name: spreadanimation;
          animation-duration: 2s;
          animation-iteration-count: infinite;

          svg {
            display: block;
            width: 20px;
            height: 20px;
            // position: absolute;
            left: 1em;
          }

          @media only screen and (max-width: 1500px) {
            svg {
              display: none;
              width: 20px;
              height: 20px;
              // position: absolute;
              left: 1em;
            }
          }

          .border {
            stroke: $status_charger-yellow;
            stroke-width: 1;
            fill: none;
            animation: borderAnimation 3s infinite;
          }

          .fill {
            fill: $status_charger-yellow;
            opacity: 0;
            animation: fillAnimation 3s infinite;
          }

          @keyframes spreadanimation {
            0% {
              box-shadow: 0px 0px 4px 4px $status-breathing-blue;
            }

            20% {
              box-shadow: 0px 0px 8px 10px $status-breathing-blue;
            }

            40% {
              box-shadow: 0px 0px 8px 10px $status-breathing-blue;
            }

            100% {
              box-shadow: 0px 0px 4px 4px $status-breathing-blue;
            }
          }

          @keyframes borderAnimation {
            0% {
              stroke-dasharray: 0, 100;
            }

            50% {
              stroke-dasharray: 100, 0;
            }

            100% {
              stroke-dasharray: 0, 100;
            }
          }

          @keyframes fillAnimation {
            0% {
              opacity: 0;
            }

            50% {
              opacity: 1;
            }

            100% {
              opacity: 0;
            }
          }
        }
      }

      .data-report_inner {
        .json-data__block {
          white-space: unset !important;

          .json--data {
            max-width: 550px;
          }
        }
      }

      .connector-charging {
        //background: #3b7ddd;
        color: $semantic-info;
        border-color: $semantic-info;
      }

      .connector-unavailable {
        color: $input-value;
        border-color: $input-value;
        color: $input-value;
        border-color: $input-value;
      }

      .connector-wrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: 200px;

        .connector-circle {
          color: $input-value;
          border-radius: 50%;
          padding: 0px 10px 0px 0px;
          font-size: 12px;
          margin-right: 10px;
          font-weight: 500;
          margin: 5px;
        }

        .available {
          svg {
            color: #0f9b72;
            //background-color: #109b7147;
            transform: rotate(317deg);
          }
        }

        .charging {
          svg {
            color: $semantic-info;
            //background-color: #3b7cdd4b;
            transform: rotate(317deg);
          }
        }

        .preparing {
          svg {
            color: $input-value;
            //background-color: #3b7cdd4b;
            transform: rotate(317deg);
          }
        }

        .unavailable {
          svg {
            color: #ff8b00;
            //background-color: rgba(190, 32, 11, 0.308);
            transform: rotate(317deg);
          }

          .Faulted {
            svg {
              color: #be210b;
              //background-color: #3b7cdd4b;
              transform: rotate(317deg);
            }
          }
        }

        .PowerLoss {
          svg {
            color: #be210b;
            //background-color: #109b7147;
            transform: rotate(317deg);
          }
        }
        .more-than-one-letter {
          border-radius: 8px;
          margin: 5px;
          font-size: 12px;
        }

        .more-than-one-letter {
          border-radius: 8px;
        }

        // .more-than-one-letter {
        //   border-radius: 8px;
        //   margin: 5px;
        //   font-size: 15px;
        // }
        // .more-than-one-letter {
        //   border-radius: 8px;
        // }
      }

      .sub-table {
        display: contents;
        transition: all 0.3s ease-in-out;

        .sub-table-head-row:first-child {
          th:first-child {
            border-top-left-radius: 8px;
            transition: all 0.3s ease-in-out;
          }

          th:last-child {
            border-top-right-radius: 8px;
            transition: all 0.3s ease-in-out;
          }
        }

        .sub-table-body-row:last-child {
          td:first-child {
            border-bottom-left-radius: 8px !important;
          }

          td:last-child {
            border-bottom-right-radius: 8px !important;
          }
        }

        .sub-table-head-row,
        .sub-table-body-row {
          th,
          td {
            border-style: none solid solid none;
            transition: all 0.3s ease-in-out;
            flex: 1;
          }
        }
      }

      .sub-table-head-row,
      .sub-table-body-row {
        border-bottom: 1px solid $table-sub-row-bg;
      }

      .sub-table-head-row th {
        padding: 0.938rem 1.563rem;
        color: $table-header;
        font-size: 0.875rem;
        font-weight: normal;
      }

      .sub-table-body-row .available {
        svg {
          color: #0f9b72;
          transform: rotate(317deg);
          margin-right: 10px;
        }
      }

      .sub-table-body-row .charging {
        svg {
          color: $semantic-info;
          transform: rotate(317deg);
          margin-right: 10px;
        }
      }

      .sub-table-body-row .preparing {
        svg {
          color: $input-value;
          transform: rotate(317deg);
          margin-right: 10px;
        }
      }

      .sub-table-body-row .unavailable {
        svg {
          color: #ff8b00;
          transform: rotate(317deg);
          margin-right: 10px;
        }
      }

      .sub-table-body-row .PowerLoss {
        svg {
          color: #be210b;
          transform: rotate(317deg);
          margin-right: 10px;
        }
      }
    }
  }
}

.loader--block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search_box_div {
  @media (min-width: 768px) and (max-width: 1200px) {
    flex: 1;
  }
}

.search_btn_download {
  display: flex;
  flex-direction: row;
  place-items: baseline;

  @media (min-width: 768px) and (max-width: 1200px) {
    gap: 15px;
  }

  .report-search-box {
    flex: 1;

    button {
      width: 100%;
    }
  }

  .excel-icon--block {
    flex: 1;
    display: flex;
    place-content: center;
  }
  // }
}

.wise_status {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 1em;
  margin-left: 1em;
}
// MultiSelectCheckBox Css
// .css-1r4vtzz {
//   background: #182532 !important;
//   color: rgba(255, 255, 255, 0.5) !important ;
//   padding: 0!important;
//   // padding: 1rem 6rem 1rem 1rem !important;
//   // display: flex !important;
//   .css-1v99tuv {
//     overflow: visible;
//     padding: 1rem 5.5rem 1rem 1rem !important;
//   }
//   .css-1gpjby2{
//     margin: 0rem 0.125rem !important;
//   }
// }

// .css-1pcexqc-container {
//   color: rgb(250, 250, 251) !important;
//   background-color: #223343 !important;
//   .css-1jllj6i-control {
//     background: #223343 !important;
//     border-color: #182532 !important ;
//   }
// }

// .css-11unzgr {
//   overflow-y: auto;
//   &::-webkit-scrollbar {
//     width: 6px;
//     background-color: transparent;
//   }
//   &::-webkit-scrollbar-track {
//     background-color: transparent;
//   }
//   &::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     background-color: rgba(195, 194, 197, 0.4);
//   }

//   .css-1gl4k7y {
//     color: rgb(250, 250, 251) !important;
//     font-weight: normal !important;
//     font-size: 0.9rem !important;
//     padding: 0.5rem 4.9rem;
//   }
// }
// .css-48ayfv {
//   background-color: #182531 !important;
//   color: rgba(255, 255, 255, 0.5) !important ;
//   padding: 0rem !important;
//   // padding: 0.75rem 3rem !important;
//   .css-1v99tuv{
//     // overflow: visible;
//     padding:1rem 5.5rem 1rem 1rem !important;
//   }
//   .css-1gpjby2{
//     margin: 0rem 0.125rem !important;
//   }
// }
// .css-1qprcsu-option {
//   background-color: #223343 !important;
//   color: rgb(250, 250, 251) !important;
//   font-weight: normal !important;
//   font-size: 0.9rem !important;
// }
// .css-1vr111p-option {
//   font-size: 0.9rem !important ;
// }
// .css-1vr111p-option:hover {
//   background-color: #223343 !important ;
// }
// .css-1gpjby2 {
//   svg {
//     stroke: currentcolor;
//     fill: currentcolor;
//     // margin-left: 3.5rem;
//     width: 12px;
//     height: 12px;
//     padding: 0 1rem 0 0.5rem;
//     stroke-width: 0;
//     color: rgb(244, 63, 63);
//     background: url('../../../svg/general/down-arrow-red.svg') no-repeat center;
//     background-size: contain;
//     padding: 0rem 1.5rem 0rem 1rem!important
//   }
// }
// .css-48ayfv {
//   .css-1v99tuv {
//     overflow: visible;
//     padding: 1rem 5.5rem 1rem 1rem;
//   }
// }
// }
// //booking-history
// .booking-history-list--main {
//   .booking-history-list-box {
//     margin-bottom: 1rem;
//     .booking-history-row {
//       margin: 0;
//       .history-list-filter {
//         background: #182532;
//         border: 1px solid #1b2a38;
//         box-sizing: border-box;
//         padding: 0.7rem;
//         cursor: pointer;
//         text-align: center;
//         margin-bottom: 0.5rem;
//       }
//       .booking-history-date-picker {
//         margin-bottom: 0.5rem;
//       }
//       .booking-history-select--box {
//         cursor: pointer;
//         .booking-history-label {
//           font-style: normal;
//           font-weight: normal;
//           font-size: 0.813rem;
//           color: #ffffff;
//           line-height: 1rem;
//           .booking-history-select-input {
//             > div {
//               background: #182532;
//               border: 1px solid #1b2a38;
//               box-sizing: border-box;
//               border-radius: 0 !important;
//               padding: 0.2rem;
//             }
//             .css-qc6sy-singleValue,
//             .css-14el2xx-placeholder {
//               margin-left: 3rem;
//               font-style: normal;
//               font-weight: normal;
//               font-size: 0.813rem;
//               line-height: 1rem;
//               color: rgba(255, 255, 255, 0.5);
//             }
//             .css-6j8wv5-Input > button,
//             input,
//             optgroup,
//             select,
//             textarea {
//               margin-left: 3rem !important;
//               font-style: normal !important;
//               font-weight: normal !important;
//               font-size: 0.813rem !important;
//               line-height: 1rem !important;
//               color: rgba(255, 255, 255, 0.5) !important;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// //charging-history
// .charging-history-list--main {
//   .charging-history-list-box {
//     margin-bottom: 1rem;
//     .charging-row {
//       margin: 0;
//       .charging-list-filter {
//         background: #182532;
//         border: 1px solid #1b2a38;
//         box-sizing: border-box;
//         padding: 0.7rem;
//         cursor: pointer;
//         text-align: center;
//         margin-bottom: 0.5rem;
//       }
//       .charging-history-date-picker {
//         margin-bottom: 0.5rem;
//       }
//     }
//   }
// }

// //station-charging-summary
// .station-charging-summary-list--main {
//   .station-charging-summary-list-box {
//     margin-bottom: 1rem;
//     .station-charging-summary-row {
//       margin: 0;
//       .station-charging-list-filter {
//         background: #182532;
//         border: 1px solid #1b2a38;
//         box-sizing: border-box;
//         padding: 0.7rem;
//         cursor: pointer;
//         text-align: center;
//         margin-bottom: 0.5rem;
//       }
//       .station-charging-summary-date-picker {
//         margin-bottom: 0.5rem;
//       }
//       .station-charging-summary-select--box {
//         cursor: pointer;
//         .station-charging-summary-label {
//           font-style: normal;
//           font-weight: normal;
//           font-size: 0.813rem;
//           color: #ffffff;
//           line-height: 1rem;
//           .station-charging-summary-select-input {
//             > div {
//               background: #182532;
//               border: 1px solid #1b2a38;
//               box-sizing: border-box;
//               border-radius: 0 !important;
//               padding: 0.2rem;
//             }
//             .css-qc6sy-singleValue,
//             .css-14el2xx-placeholder {
//               margin-left: 3rem;
//               font-style: normal;
//               font-weight: normal;
//               font-size: 0.813rem;
//               line-height: 1rem;
//               color: rgba(255, 255, 255, 0.5);
//             }
//             .css-6j8wv5-Input > button,
//             input,
//             optgroup,
//             select,
//             textarea {
//               margin-left: 3rem !important;
//               font-style: normal !important;
//               font-weight: normal !important;
//               font-size: 0.813rem !important;
//               line-height: 1rem !important;
//               color: rgba(255, 255, 255, 0.5) !important;
//             }
//           }
//         }
//       }
//       .station-charging-summary-download-icon {
//         padding: 0.7rem;
//         text-align: center;
//         background: #182532;
//         cursor: pointer;
//         svg {
//           font-size: 1.3rem;
//           color: #be210b;
//         }
//       }
//     }
//   }
// }

// .css-1r4vtzz {
//   background: #182532 !important;
//   color: rgba(255, 255, 255, 0.5) !important ;
//   padding: 0rem 2rem 0rem 0rem !important;
//   // padding: 1rem 6rem 1rem 1rem !important;
//   // display: flex !important;
//   .css-1v99tuv {
//     // overflow: visible;
//     padding: 1rem 9.5rem 1rem 1rem !important;
//   }
//   .css-1gpjby2 {
//     padding: 1rem 1.5rem 1rem 1rem !important;
//     // margin: 0rem 3rem;
//   }
// }

// .css-1pcexqc-container {
//   color: rgb(250, 250, 251) !important;
//   background-color: #223343 !important;
//   .css-1jllj6i-control {
//     background: #223343 !important;
//     border-color: #182532 !important ;
//   }
// }

// .css-11unzgr {
//   overflow-y: auto;
//   &::-webkit-scrollbar {
//     width: 6px;
//     background-color: transparent;
//   }
//   &::-webkit-scrollbar-track {
//     background-color: transparent;
//   }
//   &::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     background-color: rgba(195, 194, 197, 0.4);
//   }

//   .css-1gl4k7y {
//     color: rgb(250, 250, 251) !important;
//     font-weight: normal !important;
//     font-size: 0.9rem !important;
//     padding: 0.5rem 4.9rem;
//   }
// }
// .css-48ayfv {
//   background-color: #182531 !important;
//   color: rgba(255, 255, 255, 0.5) !important ;
//   padding: 0rem !important;
//   // padding: 0.75rem 3rem !important;
//   .css-1v99tuv {
//     // overflow: visible;
//     padding: 1rem 9.5rem 1rem 1rem;
//   }
//   .css-1gpjby2 {
//     padding: 1rem 1.5rem 1rem 1rem !important;
//   }
// }
// .css-1qprcsu-option {
//   background-color: #223343 !important;
//   color: rgb(250, 250, 251) !important;
//   font-weight: normal !important;
//   font-size: 0.9rem !important;
// }
// .css-1vr111p-option {
//   font-size: 0.9rem !important ;
// }
// .css-1vr111p-option:hover {
//   background-color: #223343 !important ;
// }
// .css-1gpjby2 {
//   svg {
//     stroke: currentcolor;
//     fill: currentcolor;
//     // margin-left: 3.5rem;
//     width: 12px;
//     height: 12px;
//     padding: 0 1rem 0 0.5rem;
//     stroke-width: 0;
//     color: rgb(244, 63, 63);
//     background: url('../../../svg/general/down-arrow-red.svg') no-repeat center;
//     background-size: contain;
//     // padding: 0rem 1.5rem !important
//   }
// }
// .css-48ayfv {
//   .css-1v99tuv {
//     // overflow: visible;
//     padding: 1rem 9.5rem 1rem 1rem;
//   }
// }
// }
// @media only screen and (max-width: 768px) {
//   .data-report__table .record-list-table {
//     display: flex;
//     flex-direction: column; // Stack cards vertically
//     gap: 15px; // Add spacing between cards
//     padding: 10px; // Add padding around the container
//   }

//   .data-report__table .record-list-table tbody {
//     display: flex;
//     flex-direction: column; // Stack rows as individual cards
//   }

//   .data-report__table .record-list-table tbody tr {
//     display: flex;
//     flex-direction: column; // Ensure card-like vertical layout
//     border: 1px solid #ccc; // Card border
//     border-radius: 10px; // Rounded corners
//     padding: 15px; // Add padding inside each card
//     box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
//     background-color: #fff; // Background color for better readability
//   }

//   .data-report__table .record-list-table tbody tr td {
//     display: flex; // Align elements horizontally
//     flex-direction: row; // Row layout for content inside the card
//     align-items: center; // Vertically align content
//     justify-content: space-between; // Distribute content evenly
//     margin-bottom: 10px; // Add space between content sections
//     width: 100%; // Ensure content spans the card width
//   }

//   .data-report__table .record-list-table tbody tr td:last-child {
//     margin-bottom: 0; // Remove margin for the last block in the card
//   }

//   .data-report__table .record-list-table tbody tr td .station-link {
//     flex: 1; // Allow the station info to take up remaining space
//     display: flex;
//     flex-direction: column; // Stack text and details
//     justify-content: center; // Align text in the center of the available space
//     text-align: left; // Align station details to the left
//     font-size: 0.9rem; // Adjust font size for better readability
//     white-space: normal; // Wrap text as needed
//   }

//   .data-report__table .record-list-table tbody tr td svg {
//     flex-shrink: 0; // Prevent icons from shrinking
//     width: 30px; // Adjust icon size
//     height: 30px;
//     margin-right: 10px; // Space between icon and text
//   }

//   .dropdown-link {
//     display: flex; // Ensure the dropdown button is aligned
//     justify-content: flex-end; // Align dropdown icon to the right
//     align-items: center; // Center align dropdown icon vertically
//   }

//   .dropdown-link svg {
//     border-radius: 5px; // Rounded dropdown icon
//     padding: 8px; // Add padding for spacing
//     width: 28px; // Adjust dropdown icon size
//     height: 28px;
//     background: $tab-active-color; // Set background for dropdown icon
//   }

//   .data-report__table .record-list-table thead {
//     display: none; // Hide the table header for mobile
//   }
// }

@media only screen and (max-width: 768px) {
  .charger-status-tr {
    display: flex;
    flex-direction: column; // Stack cards vertically
    border: 1px solid #ccc; // Add border for card structure
    border-radius: 10px; // Rounded edges for a clean look
    padding: 15px; // Add inner padding for spacing
    margin: 15px 0; // Add vertical space between cards
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
    background-color: #1b2a38; // Adjust background color to match your reference
    width: 100vw; // Ensure cards span the full width of the viewport
    box-sizing: border-box; // Include padding and border in width calculation
    overflow-x: hidden; // Prevent any content overflow
    max-width: 100%;
  }

  .charger-status-tr tr {
    display: flex;
    flex-direction: column; // Stack rows inside the card
    width: 100%; // Ensure rows fit the card width
  }

  .charger-status-tr tr td {
    display: flex; // Align content horizontally
    flex-direction: row; // Ensure icons and details align in a row
    justify-content: space-between; // Space items evenly
    align-items: center; // Center items vertically
    // margin-bottom: 10px; // Add space between content sections
    font-size: 0.9rem; // Adjust font size for readability
    word-wrap: break-word; // Wrap long text properly
    white-space: normal; // Prevent text overflow
  }

  .charger-status-tr tr td:last-child {
    margin-bottom: 0; // Remove extra margin for the last section
  }

  .charger-status-tr .station-link td {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: left;
    font-size: 0.9rem;
    margin-left: 10px;
    padding-left: 10px;
  }

  .station-link span {
    margin-bottom: 5px; // Add spacing between station details
    line-height: 1.4; // Increase line height for better readability
  }

  .status_charger {
    border-radius: 20px; // Round badges for a clean look
    padding: 5px 15px; // Add compact padding
    font-size: 0.85rem; // Adjust text size
    text-align: center; // Center the badge text
    color: #fff; // Set white text color for contrast
  }

  .status-Available {
    background-color: #0f9b72; // Green for Available status
  }

  .status-PowerLoss {
    background-color: #be210b; // Red for Power Loss status
  }

  .status-Unavailable {
    background-color: #ff8b00; // Orange for Unavailable status
  }

  .status-Charging {
    background-color: #007bff; // Blue for Charging status
    box-shadow: 0px 0px 4px 4px rgba(0, 123, 255, 0.5); // Add glow effect
  }

  .dropdown-link {
    display: flex; // Flex for alignment
    justify-content: flex-end; // Align dropdown to the right
    align-items: center; // Center the dropdown icon
  }

  .dropdown-link svg {
    border-radius: 5px; // Rounded dropdown icon
    padding: 8px; // Adjust spacing around the icon
    width: 28px; // Adjust size for compactness
    height: 28px;
    background: #007bff; // Match dropdown background to your reference
  }
}
// normal screen
.charger-status-tr {
  display: flex;
  flex-direction: column;
  border-radius: 20.453px;
  border: 1.023px solid $tab-active-color;
  margin: 10px 0px;
}

.charger-status-tr tr td:nth-child(3),
.charger-status-tr tr th:nth-child(3) {
  flex: 8% !important;
}

@media only screen and (max-width: 350px) {
  .data-report--main .data-report--block .data-report__inner .data-report__table .record-list-table.chargerstatus-list-table .charger-status-tr tr {
    width: 200px;
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .data-report--main
    .data-report--block
    .data-report__inner
    .data-report__table
    .record-list-table.chargerstatus-list-table
    .station-table-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 2fr));
    gap: 10px;
    border-radius: 20.453px;
    border: 1.023px solid #1e3249;
    margin: 10px 0px;
    margin-left: 55px;
  }
}
