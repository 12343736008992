.alert-item {
  display: flex; 
  align-items: center; 
  padding: 15px;
  margin: 1em 0; 
  border-left: 5px solid $sidebar-menu-item; 
  transition: border-color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  background: $gray-700;
  .alert-icon {
    margin-right: 15px;
    font-size: 1.5em;
  }
  .alert-content {
    flex-grow: 1; 
    display: flex;
    flex-direction: column;
    .alert-message {
      font-weight: bold;
      color: $sidebar-menu-item; 
    }
    
    .alert-date {
      color: $sidebar-menu-item;
      margin-top: 5px; 
      font-size: 12px;
    }
    .alert-btn-modal
    {
      display: flex;
      gap:1em;
      margin: 0.5em 0em;
    }
  }

}

.alert-red {
  border-left: 5px solid $button-primary; 
  .alert-icon 
  {
    color:$button-primary
  }
  .alert-content
  {
   .alert-message 
    {
      color:$button-primary
    }
  }

}

.alert-yellow {
  border-left: 5px solid $status_charger-yellow; 
  .alert-icon
    {
      color:$status_charger-yellow;
    }
  .alert-content
  {
   .alert-message 
    {
      color:$status_charger-yellow;
    }
  }
 
}

.alert-green {
  border-left: 5px solid $toggle-checked;
  .alert-icon 
  {
    color:$toggle-checked;
  }
  .alert-content{
    .alert-message 
    {
      color:$toggle-checked;
    }
  }

}

.alert-modal
{
  color:$input-value;
}
.modal-2xl {
  max-width: 95vw;
  width: 1400px;
  margin: 1.75rem auto;
  
  @media (max-width: 1500px) {
    width: 95vw;
  }

  @media (max-width: 768px) {
    width: 90vw;
    margin: 1rem;
  }
}

.modal-xl {
  max-width: 85vw;
  width: 1400px;
  margin: 1.75rem auto;
  
  @media (max-width: 1500px) {
    width: 95vw;
  }

  @media (max-width: 768px) {
    width: 90vw;
    margin: 1rem;
  }
}
.alert-modal-content{
  display: flex;
  flex-direction: row;
  // flex-wrap: nowrap;
  justify-content: space-around;
  // width: 100%;
  

  @media (max-width: 1500px) {
    overflow-x: scroll;
    display: flex;
    gap:15px;
  }
}


.alert-modal-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  button{
    background: none;
    border: none;
    color: $button-primary;
    font-size: 1.5em;
    cursor: pointer;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
   
  }
  25% {
    opacity: 0.8;
  
  }
  50% {
    opacity: 0.5;
    
  }
  75% {
    opacity: 0.8;
  
  }
  100% {
    opacity: 1;
   
  }
}

.blinking {
  animation: blink 1.2s ease-in-out infinite;
}

.blinking-yellow {
  animation: blink 1.5s ease-in-out infinite;
}


.details-section {
  flex: 1;
  min-width: 450px;
  max-width: calc(33.33% - 1rem);
  margin: 0;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #eee;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  height: 60vh;
  // max-height: 60vh; // Constrain height to 60% of viewport
  overflow-y: auto; // Allow scrolling for overflowing content
  @media (max-width: 992px) {
    max-width: 100%;
  }
}


.details-section:last-child {
  border-bottom: none;
}

.section-heading {
  font-weight: bold;
  color: $button-primary;
  border-left: 4px solid $button-primary;
  padding-left: 0.5rem;
}
.list-group-flush > .list-group-item{
  flex:50%;
}