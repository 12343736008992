.checklist-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    font-size: 1rem;
    margin-bottom: 1rem;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 500;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.checklist-label {
    font-size: 1rem;
    margin-bottom: 0.7rem;
    color: rgba(255, 255, 255, 0.5019607843);
    font-weight: 500;
    margin-right: 1rem;
}

.custom-checkbox {
    margin-top: 5px;
    margin-left: 10px;
}

.field-description {
    font-size: 0.875rem;
    margin-bottom: 0.7rem;
    color: rgba(255, 255, 255, 0.5019607843);
    font-style: normal;
    font-weight: 400;
    line-height: 1.7;
    margin-top: 5px;
}

.custom-select {
    border: 1px solid #3b4a56;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    background: transparent;
    box-sizing: border-box;
    border-radius: 15px !important;
    padding: 1rem;
    padding-right: 50px;
}

.save-button {
    margin-top: 20px;
    text-align: right;
}

.checkbox-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.select-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.calculation-input {
    margin-top: 10px;
}

.checklist-container {
    margin: 50px;
}

.section_title {
    font-size: large;
    font-weight: 500;
    margin-bottom: 20px;
}

.switch-container {
    margin-top: 30px;
}

section {
    border: 1px solid #2e3841;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
    padding: 20px;
}