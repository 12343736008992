.tab-block {
  padding: 20px 0;
  .tab-nav-block {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #2e3841;
    .tab-nav__item {
      border-bottom: 2px solid transparent;
      padding: 0.7rem 1.5rem;
      margin-bottom: 10px;
      margin-right: 5px;
      color: $gray-200;
      font-size: 0.9rem;
      font-weight: 500;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      border-radius: 15px;
    }
    .active {
      color: $input-value;
      background-color: $tab-active-color;
    }
    &__right {
      margin-left: auto;
    }
  }
  .tab-content-block {
    .tab-content__item {
      display: none;
      padding: 1.8rem 0;
    }
    .active {
      display: block;
    }
  }
}
@media (max-width: 1024px) {
  // .tab-block .tab-nav-block .tab-nav__item {
  //   padding: 1rem 0;
  // }
  .primary-button {
    padding: 0.4rem 0.8rem;
  }
}
@media (max-width: 768px) {
  // .tab-block .tab-nav-block .tab-nav__item {
  //   margin: 0 0.5rem;
  // }
  .primary-button {
    padding: 0.4rem 0.5rem;
  }
}
