.main-card-container {
  background: $brand-primary;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
  border-radius: 20px;
  margin-bottom: 1rem;
  color: #fff;
  .card-body {
    padding: 1.5rem 2rem;
  }
}
