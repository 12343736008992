.layout-container {
  position: relative;
  height: auto;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}
.main-layout-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: $input-value;
  background-color: $brand-primary;
  .content-layout-wrapper__right {
    position: relative;
    z-index: 0;
    width: 100%;
    float: right;
    min-height: 100%;
    overflow-x: hidden;
  }
}

.page-content-wrapper {
  width: 100%;
  height: calc(100vh - 5.5rem);
  overflow-x: hidden;
  padding: 2rem !important;
  background-color: $gray-700;
  z-index: -1;
  position: relative;
  border-radius: 30px 0px 0px 0px;
  @media only screen and (max-width: 768px) {
    padding: 1rem !important;
    border-radius: 0%;
  }
  .empty-data-block {
    text-align: center;
    font-size: 0.9rem;
    color: $sidebar-menu-item;
  }
  .button--block {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-bottom: 1rem;
  }
}

// Modal css
.modal {
  .modal-dialog {
    .modal-content {
      background-color: $brand-primary;
      border-radius: 25px;
    }
  }
}
